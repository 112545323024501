import http from "@/utils/request";

//运营数据
export function getOperatePlanReach(data) {
    return http({
        method: "get",
        // url: "/coreFunction/findEvaluation.nd",
        url:'/evaluation/findComprehensiveEvaluation.nd',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}


//合并数据导出
export function exportAllInfoList(data) {
    return http({
        method: "post",
        url: "/customer/check/export.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob',
        data
    });
}
export function reportUnionAll(data) {
    return http({
        method: "post",
        url: "/fast/userReport/reportUnionAll/export.nd",
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        responseType: 'blob',
        data
    });
}


//月度履约进度
export function getfindPerformanceProcess(data) {
    return http({
        method: "get",
        url:'/exclusive/findPerformanceProcess.nd',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}
// 库存周转天数
export function getfindInventoryTurnover(data) {
    return http({
        method: "get",
        url:'/exclusive/findInventoryTurnover.nd',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}
// 建店累计完成率
export function getfindBuildStore(data) {
    return http({
        method: "get",
        url:'/exclusive/findBuildStore.nd',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}
// 专供机占比数据
export function getfindDedicatedProduct(data) {
    return http({
        method: "get",
        url:'/exclusive/findDedicatedProduct.nd',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}

// 中高端数据
export function getfindMidHigh(data) {
    return http({
        method: "get",
        url:'/exclusive/findMidHigh.nd',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        params:data
    });
}
export function queryShopMessage(data) {
    return http({
        method: "post",
        url:'/shopNotifyMessage/queryShopMessage.nd',
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        data:data
    });
}

