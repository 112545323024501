import "echarts-liquidfill/src/liquidFill.js";
import * as echarts from "echarts"
import moment from "moment";
import Util from "@/utils/utils";
import {
  getOperatePlanReach,
  getfindPerformanceProcess,
  exportAllInfoList,
  reportUnionAll,
  getfindInventoryTurnover, getfindBuildStore, getfindDedicatedProduct, getfindMidHigh, queryShopMessage
} from "./api";
import { mapState } from "vuex";
import { SalesShopInfoList } from "@/views/confirmAgain/api";

export default {

  data() {
    return {
      activeNameStyle: "height: 66px; overflow: hidden;",
      showNameStyle: "min-height: 66px;",
      isShowNameType: true,
      activeNameStyle1: "height: 66px; overflow: hidden;",
      showNameStyle1: "min-height: 66px;",
      isShowNameType1: true,
      activeNameIndex: null,
      aticity: false,
      navList: [],
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "",
          name: "/specialStoreCE",
          title: "专卖店综合评价"
        }
      ],
      filForm: {},
      isLoading: false,
      tableData: [],
      zonghedefr: 0.0,
      pageLoadFlag: false,
      paiming: "",
      custnum:'--',
      currentPreviousDay:'',
      NetIDN:{},// 分销网络净增数据
      DSRDNetwork:{},// 分销网络动销率
      SingleStoreOutput:{}, //分销网络单店产出
      IncrementalBusiness:{},//增量业务
      PDedicatedAircraft:{},//专供机占比
      channelObj:{},// 全渠道
      PMHEnd:{}, // 中高端占比
      monishuju:[
        {
          name1:111,
          name2:80,
          name3:131,
        },
        {
          name1:222,
          name2:70,
          name3:44,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },
        {
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },{
          name1:444,
          name2:50,
          name3:4,
        },

      ],
      isCherkZH: false,// 展示选中数据需要删除图标
      isShowMoreZH: false,// 综合更多选项展开收起
      isHaveVal: false,// 综合前边的删除图标是否展示
      filterLoadFlag: false,// 排序展示数据loading效果
      cherZhname: "",// 排序选中的名称
      orderBy:'',
      shopTypeId:'',
      PxList: [],// 排序筛选列表
      customerNum:'',// 商家总数
      comprehensiveRanking:'', // 排名
      comprehensiveScore:0,// 综合得分
      pieData:[
        {value: 0, name: '月度履约进度(30) '},
        {value:0, name: '学习综合得分(20)'},
        {value: 0, name: '专供产品占比(5)'},
        {value: 0, name: '中高端产品占比(15) '},
        {value: 0, name: '库存周转天数(20) '},

      ],// 饼图数据
      showDlBox:false,
      // 月度履约进度数据
      performanceSchedule:{
        monthDeliveryTask:'',
        monthDeliveryTotalAmount:'',
        monthPerformanceSchedule:'',
        monthPerformanceSchedulejd:0,
      },
      // 库存数据
      inventoryTurnoverData:{
        "turnoverDays": "0",//库存周转天数
        "turnoverNum": 0,//库存数量
        "chainDay": "0",//较上月（天数）
        "chainNum": "0",//较上月（数量）
        "yearDay": 0,//较去年（天数）
        "yearNum": 0,//较去年（数量）
        "arrowChainDay":null ,//库存周转天数 环比(较上月)增长幅箭头 1:正，0：负
        "arrowChainNum": null,//库存周转数量 环比(较上月)增长幅箭头 1:正，0：负
        "arrowYearDay": null,//库存周转天数 同比(较去年)增长幅箭头 1:正，0：负
        "arrowYearNum": null//库存周转数量 同比(较去年)增长幅箭头 1:正，0：负

      },
      //  建店数据
      buildStoreData:{
        annualTask:'',
        annualWeightActual:'',
        completion:0,
        completionjd:0,
      },
      // 专供机占比
      dedicatedProductData:{
        "dedicatedRetailAmount": "0",//专供产品零售额
        "totalRetailAmount": "0",//门店零售额
        "ratio": "0",//专供产品占比
        "chain": null,//较上月
        "arrowMonth": null,//同比(较上月)增长幅箭头 1:正，0：负
        "year": null,//较上年
        "arrowYear": null,//环比(较上年)增长幅箭头 1:正，0：负
        "categoryRatioList": []
      },
      // 中高端占比数据
      midHighData: {
        "totalAmount": "0",//总额（高、中高、低、中低的和）
        "midHighAmount": "0",//中高端销售额(高端+中高端)
        "midHighRatio": "0%",//中高端占比(高端+中高端)
        "chain": "0",//较上月（中高端）
        "arrowMonth": "0",//较上月增幅箭头 0标识负值，1标识正值得
        "year": null,//较上年（中高端）
        "arrowYear": null,//较上年增幅箭头 0标识负值，1标识正值得
        "midLowAmount": "0",//中低端销售额(低端+中低端)
        "midLowRatio": "0%",//中低端占比(低端+中低端)
        "categoryRatioList": [//各品类中高端占比集合
          // {
          //   "categoryName": "海信洗衣机",//品类名称
          //   "ratio": "100.0%"//占比
          // }
        ]
      },
      queryTime:'',
      shopTypeName:''
    };
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
    })
  },
  created() {
    if(this.$store.state.user.userInfo.isAgentOperator == 1) {
      this.showDlBox = true
    } else {
      this.showDlBox = false
    }
    this.filForm.date = moment(new Date()).format("YYYY-MM");
  },
  mounted() {


    this.getYesterday()
    this.getShopLLIst()


  },
  methods: {
    //综合排序展开收起
    moreClick() {
      this.isShowMoreZH = !this.isShowMoreZH;
      if (this.isShowMoreZH) {
        this.isShowMoreYH = false;
      }
      // //页面元素点击时间 -》 更多筛选监听后关闭
      // this.pageElClickTime = new Date().getTime();
    },
    getShopType(id){
      let data ={
        id:id
      }
      queryShopMessage(data).then(res=>{
        if(res.data.code == 0){
          this.shopTypeName = res.data.data.name
        } else {
          this.shopTypeName = ''
        }
      }).catch(error=>{
        console.log(error);
      })
    },
    // 获取专卖店列表
    getShopLLIst() {
      let data ={
        exclusiveShop:1
      }
      SalesShopInfoList(data).then(res=>{
        if(res.data.code == 0) {
          this.PxList = res.data.list
          this.orderBy = ''
          this.shopTypeId = ''
          if(this.$route.query.shopId && this.$route.query.cherZhname) {
            this.orderBy = this.$route.query.shopId
            this.cherZhname = this.$route.query.shopName
            this.shopTypeId = this.$route.query.shopTypeId
            this.getShopType(this.shopTypeId)
          } else if( this.PxList  && this.PxList .length > 0) {
            this.orderBy = this.PxList[0].shopCisCode
            this.cherZhname = this.PxList[0].name
            this.shopTypeId = this.PxList[0].code
            this.getShopType(this.shopTypeId)
          } else {
            this.orderBy = ''
            this.shopTypeId = ''
            this.cherZhname = ''
            this.shopTypeName = ''
          }
        } else {
          this.PxList = []
        }


        this.$nextTick(()=>{
          // 如果是代理商
          if(this.$store.state.user.userInfo.isAgentOperator == 1) {
            this.showDlBox = true
            //  建店
            this.getJD()
          } else {
            this.showDlBox = false
            // 如果不是代理商  展示库存// 库存
            this.getKucun()
          }
          this.getuedu()
          // 专卖店指标图 暂无数据
          this.drawCharts();
          //专供机占比数据
          this.getzgj()
          // 中高端数据
          this.getzgd()
        })
      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 修改综合选项
    changeZH(item) {
      // this.getShopLLIst(this.shopTypeId)
      this.orderBy = item.shopCisCode;
      this.shopTypeId = item.code
      this.cherZhname = item.name;
      this.$forceUpdate()
      if (this.orderBy) {
        this.isHaveVal = true;
      } else {
        this.isHaveVal = false;
      }
      this.isShowMoreZH = false;


      this.$nextTick(()=>{
        // 如果是代理商
        if( this.$store.state.user.userInfo.isAgentOperator == 1) {
          this.showDlBox = true
          //  建店
          this.getJD()

        } else {
          this.showDlBox = false
          // 如果不是代理商  展示库存// 库存
          this.getKucun()
        }
        this.getuedu()
        // 专卖店指标图 暂无数据
        this.drawCharts();
        //专供机占比数据
        this.getzgj()
        // 中高端数据
        this.getzgd()
      })


    },
    // 删除选中项目
    delectZH() {
      this.isShowMoreZH = false;
      this.isHaveVal = false;
      this.orderBy =  this.PxList.length>0?this.PxList[0].shopCisCode:'';
      this.shopTypeId =this.PxList.length>0?this.PxList[0].code:''
      this.cherZhname = this.PxList.length>0?this.PxList[0].name:''
      this.$forceUpdate();
      this.getShopLLIst(this.shopTypeId)
      this.$nextTick(()=>{
        // 如果是代理商
        if(this.$store.state.user.userInfo.isAgentOperator == 1) {
          this.showDlBox = true
          //  建店
          this.getJD()

        } else {
          this.showDlBox = false
          // 如果不是代理商  展示库存// 库存
          this.getKucun()
        }
        this.getuedu()
        // 专卖店指标图 暂无数据
        this.drawCharts();
        //专供机占比数据
        this.getzgj()
        // 中高端数据
        this.getzgd()
      })

    },
    dateChange(e) {
      this.filForm.date = moment(e._d).format("YYYY-MM");
    },
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      this.currentPreviousDay = day1.getFullYear()+"/" + (day1.getMonth()+1) + "/" + day1.getDate();

      let Y = day1.getFullYear(); // 年
      let M = (day1.getMonth() + 1 < 10 ? '0' + (day1.getMonth() + 1) : day1.getMonth() + 1);//月
      let D = (day1.getDate() < 10 ? '0' + (day1.getDate() ) : day1.getDate())
      this.queryTime = Y + '-' + M+'-'+D
    },
    getRepostData(){
      this.pageLoadFlag = true;
      let data = {
        queryTime	: this.queryTime,
        cisCode: this.$store.state.user.userInfo.customer.cisCode
      }
      getOperatePlanReach(data).then(res=>{
        //分销网络净增
        this.NetIDN = {}
        //分销网络动销率
        this.DSRDNetwork = {}
        //分销网络单店产出
        this.SingleStoreOutput = {}
        //增量业务(前置渠道)
        this.IncrementalBusiness = {}
        //全渠道口径出货
        this.channelObj = {}
        // 中高端占比
        this.PMHEnd = {}
        // 专供机占比
        this.PDedicatedAircraft = {}
        if(res.data.code == 0) {
          // 商家总数
          this.customerNum =  res.data.data.customerNum ?res.data.data.customerNum: '--'
          // 排名
          this.comprehensiveRanking = res.data.data.comprehensiveRanking ?res.data.data.comprehensiveRanking: '--'
          // 饼图数据
          this.pieData = []
          if(res.data.data.summaryScoreList && res.data.data.summaryScoreList.length > 0){
            res.data.data.summaryScoreList.forEach(item=>{
              item.fullScore = parseInt(item.fullScore)
              this.pieData.push({
                value:item.score,
                name:item.name+'('+item.fullScore+')'
              })
            })
          }  else  {
            this.pieData = [
              {value: 0, name: '月度履约进度(30) '},
              {value:0, name: '学习综合得分(20)'},
              {value: 0, name: '专供产品占比(5)'},
              {value: 0, name: '中高端产品占比(15) '},
              {value: 0, name: '库存周转天数(20) '},
            ]
          }
          // 综合得分
          this.comprehensiveScore = res.data.data.comprehensiveScore ?res.data.data.comprehensiveScore: '--'
          if(res.data.data.individualEvaluations && res.data.data.individualEvaluations.length > 0) {
            res.data.data.individualEvaluations.forEach(item=>{
              if(item.functionName == '分销网络净增') {
                this.NetIDN = item
              }
              if(item.functionName == '分销网络动销率') {
                this.DSRDNetwork = item
              }
              if(item.functionName == '分销网络单店产出') {
                this.SingleStoreOutput = item
              }
              if(item.functionName==' 增量业务(前置渠道)' || item.functionName=='增量业务(前置渠道)') {
                this.IncrementalBusiness = item
              }
              if(item.functionName == '全渠道口径出货') {
                this.channelObj = item
              }
              if(item.functionName == '高中端占比') {
                this.PMHEnd = item
              }
              if(item.functionName == '专供机占比') {
                this.PDedicatedAircraft = item
              }
            })
          }
          this.drawCharts()
          this.pageLoadFlag = false

        }else {
          this.pieData = [
            {value: 0, name: '月度履约进度(30) '},
            {value:0, name: '学习综合得分(20)'},
            {value: 0, name: '专供产品占比(5)'},
            {value: 0, name: '中高端产品占比(15) '},
            {value: 0, name: '库存周转天数(20) '},
          ]
          this.drawCharts()
        }
      }).catch(error=>{
        this.pageLoadFlag = false;
      })
    },
    // 月度履约数据查询
    getuedu(){
      let data  = {
        cisShopCode:this.orderBy ,
        queryTime: this.queryTime,
      }
      this.performanceSchedule.monthPerformanceSchedulejd = 0
      getfindPerformanceProcess(data).then(res=>{
        this.performanceSchedule.monthDeliveryTask =  res.data.data.performanceSchedule.monthDeliveryTask?res.data.data.performanceSchedule.monthDeliveryTask:0 //总计
        this.performanceSchedule.monthDeliveryTotalAmount = res.data.data.performanceSchedule.monthDeliveryTotalAmount?res.data.data.performanceSchedule.monthDeliveryTotalAmount:0 // 实际
        this.performanceSchedule.monthPerformanceSchedule =  res.data.data.performanceSchedule.monthPerformanceSchedule?res.data.data.performanceSchedule.monthPerformanceSchedule:0// 进度
        if(this.performanceSchedule.monthPerformanceSchedule&&this.performanceSchedule.monthPerformanceSchedule.indexOf('%')!==-1) {
          this.performanceSchedule.monthPerformanceSchedule = this.performanceSchedule.monthPerformanceSchedule.split('%')[0]
          if( this.performanceSchedule.monthPerformanceSchedule.indexOf(',')!==-1) {
            this.performanceSchedule.monthPerformanceSchedulejd =  this.performanceSchedule.monthPerformanceSchedule.replace(/,/g, '');
          } else {
            this.performanceSchedule.monthPerformanceSchedulejd =  this.performanceSchedule.monthPerformanceSchedule
          }

        } else if(this.performanceSchedule.monthPerformanceSchedule&&this.performanceSchedule.monthPerformanceSchedule.indexOf('%')==-1) {
          this.performanceSchedule.monthPerformanceSchedulejd=this.performanceSchedule.monthPerformanceSchedule
        } else {
          this.performanceSchedule.monthPerformanceSchedule = 0
        }
        this.$nextTick(()=>{
          this.drawChartsone()
        })
        this.$forceUpdate()
      })
    },
    // 月度履约进度
    drawChartsone() {
      var myChart = this.$echarts.init(this.$refs.oneEcharts);
      this.$forceUpdate()
      // let value = this.performanceSchedule.monthPerformanceSchedulejd>=100?100:
      //     this.performanceSchedule.monthPerformanceSchedulejd
      let value= this.performanceSchedule.monthPerformanceSchedulejd
      let max = 100;
      let option={
        backgroundColor:"#F4F0FF",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:40,
              height:20,
              position:'right',
              offset:[-26,0],
              borderWidth:1,
              borderColor:'#888AEA',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return param.value + '%';
              },
              textStyle:{
                color: '#888AEA',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#8F98FF'
              },

            },
            barWidth: 14,
            data: [value]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 库存数据查询
    getKucun(){
      let data  = {
        cisShopCode:this.orderBy ,
        queryTime: this.queryTime,
      }
      getfindInventoryTurnover(data).then(res=>{
          if(res.data.code == 0){
            this.inventoryTurnoverData.turnoverDays = res.data.data.inventoryTurnover.turnoverDays?res.data.data.inventoryTurnover.turnoverDays:0
            this.inventoryTurnoverData.turnoverNum = res.data.data.inventoryTurnover.turnoverNum?res.data.data.inventoryTurnover.turnoverNum:0
            this.inventoryTurnoverData.chainDay = res.data.data.inventoryTurnover.chainDay?res.data.data.inventoryTurnover.chainDay:0
            this.inventoryTurnoverData.chainNum = res.data.data.inventoryTurnover.chainNum?res.data.data.inventoryTurnover.chainNum:0
            this.inventoryTurnoverData.yearDay = res.data.data.inventoryTurnover.yearDay?res.data.data.inventoryTurnover.yearDay:0
            this.inventoryTurnoverData.yearNum = res.data.data.inventoryTurnover.yearNum?res.data.data.inventoryTurnover.yearNum:0
            this.inventoryTurnoverData.arrowChainDay = res.data.data.inventoryTurnover.arrowChainDay?res.data.data.inventoryTurnover.arrowChainDay:null
            this.inventoryTurnoverData.arrowChainNum = res.data.data.inventoryTurnover.arrowChainNum?res.data.data.inventoryTurnover.arrowChainNum:null
            this.inventoryTurnoverData.arrowYearDay = res.data.data.inventoryTurnover.arrowYearDay?res.data.data.inventoryTurnover.arrowYearDay:null
            this.inventoryTurnoverData.arrowYearNum = res.data.data.inventoryTurnover.arrowYearNum?res.data.data.inventoryTurnover.arrowYearNum:null
            this.$forceUpdate()
          }else {
            this.$message.warning(res.data.msg)
          }
        }).catch(error=>{
      })
    },
    // 建店累计完成率数据
    getJD() {
      let data  = {
        cisShopCode:this.orderBy ,
        queryTime:this.queryTime,
      }
      this.buildStoreData.completionjd = 0
      getfindBuildStore(data).then(res=>{
        if(res.data.code == 0) {
          this.buildStoreData.annualTask =  res.data.data.buildStore.annualTask?res.data.data.buildStore.annualTask:0 //总计
          this.buildStoreData.annualWeightActual =  res.data.data.buildStore.annualWeightActual?res.data.data.buildStore.annualWeightActual:0 //实际
          this.buildStoreData.completion =  res.data.data.buildStore.completion?res.data.data.buildStore.completion:0//进度
          if(this.buildStoreData.completion&&this.buildStoreData.completion.indexOf('%')!==-1) {
            this.buildStoreData.completion = this.buildStoreData.completion.split('%')[0]
            if( this.buildStoreData.completion.indexOf(',')!==-1) {
              this.buildStoreData.completionjd= this.buildStoreData.completion.replace(/,/g, '');
            } else {
              this.buildStoreData.completionjd= this.buildStoreData.completion
            }
          } else if(this.buildStoreData.completion&&this.buildStoreData.completion.indexOf('%')==-1) {
            this.buildStoreData.completion = this.buildStoreData.completion
            this.buildStoreData.completionjd = this.buildStoreData.completion
          } else {
            this.buildStoreData.completion = 0
            this.buildStoreData.completionjd = 0
          }
          this.$nextTick(()=>{
            this.drawChartstwoEcharts()
          })
          this.$forceUpdate()
        } else {
          this.buildStoreData.completion = 0
          this.buildStoreData.completionjd = 0
          this.drawChartstwoEcharts()
        }
      }).catch(error=>{
      })
    },
    // 第一个div里面的图表
    drawChartstwoEcharts() {
      var myChart = this.$echarts.init(this.$refs.two2Echarts);
      this.$forceUpdate()
      let max = 100;
      let value = this.buildStoreData.completionjd
      let option={
        backgroundColor:"#ECF8F8",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:40,
              height:20,
              position:'right',
              offset:[-26,0],
              borderWidth:1,
              borderColor:'#18D1BC',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return param.value + '%';
              },
              textStyle:{
                color: '#18D1BC',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#18D1BC'
              },

            },
            barWidth: 14,
            data: [value]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 第三个图标
    drawCharts() {
      var myChart = this.$echarts.init(this.$refs.three);
      var dataA = this.pieData
      this.$forceUpdate()
      let option =
        {
          color: [new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: '#8385E9'
            },
            {
              offset: 0.9,
              color: '#BFC1F7'
            }
          ]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#22C7BB'
            },
              {
                offset: 0.9,
                color: '#9DFDD5'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F36556'
            },
              {
                offset: 0.9,
                color: '#FFAF9B'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F69A52'
            },
              {
                offset: 0.9,
                color: '#F8CA6C'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#8EA2C2'
            },
              {
                offset: 0.9,
                color: '#BDC2D2'
              }])
          ],
          legend: {
            selectedMode: false,
            icon: "circle",
            left: "55%",
            y: "center", //延Y轴居中
            // x: '30%', //居右显示
            // orient: "vertical",
            color: "#ffffff",
            right: 20, //右边距0
            top: '46%',
            // icon: "pie",
            itemWidth: 8, // 设置宽度
            itemHeight: 8, // 设置高度
            // itemGap: this.$util.setFont(12), // 设置间距
            itemGap: 16, // 设置间距
            formatter: function(name) {
              var value;
              for (let i = 0, l = dataA.length; i < l; i++) {
                if (dataA[i].name == name) {
                  value = dataA[i].value;
                }
              }
              var str = "{a|" + name + "}{b|" + value + "}";
              return str;
            },
            textStyle: {
              rich: {
                a: {
                  color: "#777",
                  fontSize: 14,
                  width: 160,
                  padding: [0, 0, 0, 2] //间距
                },
                b: {
                  color: "#262626",
                  fontSize: 14,
                  // padding: [0, 0, 16, 2] //间距
                }
              }
            }
          },
          series: [
            {
              // hoverAnimation: false,
              name: '',
              type: 'pie',
              center: ['30%', '60%'],
              radius: ['49%', '62%'],
              avoidLabelOverlap: false,
              clockWise: false,
              itemStyle: {
                normal: {
                  borderColor: '#f7f7f7',
                  borderWidth: 4
                }
              },
              label: {
                show: false,
                position: 'center'
              },

              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.pieData
            },
            // 边框的设置
            {
              hoverAnimation: false,
              radius: ['44%', '46%'],
              center: ['30%', '60%'],
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  color: "#C9EEF2",
                },
              }],
            },
            // 内圆设置
            {
              radius: [0, '45%'],
              center: ['30%', '60%'],
              hoverAnimation: false,
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  normal: {
                    color: { // 完成的圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: "#F9FDFE" // 0% 处的颜色
                      }, {
                        offset: 0.4,
                        color: "#E0F6F8" // 100% 处的颜色
                      }]
                    },
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  }
                },
              }],
            }
          ]
        }

      myChart.setOption(option);
    },

    // 学习综合得分
    drawStudyCharts() {
      var myChart = this.$echarts.init(this.$refs.studyZH);
      var echartData = [{
        value: 0,
        name: ''
      }, {
        value: 0,
        name: ''
      }]
      let  option = {
        // backgroundColor: '#ECF8F8',
        series: [{
          name: '',
          type: 'pie',
          radius: ['58%', '70%'],
          hoverAnimation: false,
          color: ['#1890FF', '#18D1BC'],
          avoidLabelOverlap: false,
          clockWise: false,label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          data: echartData
        }]
      };
      myChart.setOption(option);

    },

    //专供机占比数据
    getzgj(){
      let data  = {
        cisShopCode:this.orderBy ,
        queryTime: this.queryTime,
      }
      getfindDedicatedProduct(data).then(res=>{
        if(res.data.code == 0) {
          //专供产品零售额
          this.dedicatedProductData.dedicatedRetailAmount = res.data.data.dedicatedProduct.dedicatedRetailAmount? res.data.data.dedicatedProduct.dedicatedRetailAmount:0
          //专供产品占比
          this.dedicatedProductData.ratio = res.data.data.dedicatedProduct.ratio? res.data.data.dedicatedProduct.ratio:0
          // 较上月
          this.dedicatedProductData.chain = res.data.data.dedicatedProduct.chain? res.data.data.dedicatedProduct.chain:0
          // 较去年
          this.dedicatedProductData.year = res.data.data.dedicatedProduct.year? res.data.data.dedicatedProduct.year:0
          // 较上月符号
          this.dedicatedProductData.arrowMonth = res.data.data.dedicatedProduct.arrowMonth? res.data.data.dedicatedProduct.arrowMonth:null
          // 较去年符号
          this.dedicatedProductData.arrowYear = res.data.data.dedicatedProduct.arrowYear? res.data.data.dedicatedProduct.arrowYear:null
          //  循环数据
          this.dedicatedProductData.categoryRatioList = res.data.data.dedicatedProduct.arrowYear? res.data.data.dedicatedProduct.arrowYear:[]
          this.dedicatedProductData.categoryRatioList=res.data.data.dedicatedProduct.categoryRatioList
          // 如果数据大于0  截取%之前 赋值
          if( this.dedicatedProductData.categoryRatioList.length > 0) {
            this.dedicatedProductData.categoryRatioList.forEach(item=>{
              if(item.ratio) {
                item.ratio =  Number(item.ratio.split('%')[0])
              } else {
                item.ratio = 0
              }
            })
          }
          this.$forceUpdate()
        }else {
          this.$message.warning(res.data.msg)
        }

      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 获取中高端
    getzgd(){
      this.pageLoadFlag = true
      let data  = {
        cisShopCode:this.orderBy ,
        queryTime: this.queryTime,
      }
      getfindMidHigh(data).then(res=>{
        if(res.data.code == 0) {
          this.midHighData.totalAmount = res.data.data.midHigh.totalAmount?res.data.data.midHigh.totalAmount:0

          this.midHighData.midHighAmount = res.data.data.midHigh.midHighAmount?res.data.data.midHigh.midHighAmount:0

          this.midHighData.midLowAmount = res.data.data.midHigh.midLowAmount?res.data.data.midHigh.midLowAmount:0
          this.midHighData.midHighRatio = res.data.data.midHigh.midHighRatio?res.data.data.midHigh.midHighRatio:0
          this.midHighData.midLowRatio = res.data.data.midHigh.midLowRatio?res.data.data.midHigh.midLowRatio:0
          this.midHighData.chain = res.data.data.midHigh.chain?res.data.data.midHigh.chain:0
          this.midHighData.year = res.data.data.midHigh.year?res.data.data.midHigh.year:0
          this.midHighData.arrowMonth = res.data.data.midHigh.arrowMonth?res.data.data.midHigh.arrowMonth:null
          this.midHighData.arrowYear = res.data.data.midHigh.arrowYear?res.data.data.midHigh.arrowYear:null
          this.midHighData.categoryRatioList = res.data.data.midHigh.categoryRatioList
          if(this.midHighData.categoryRatioList.length > 0) {
            this.midHighData.categoryRatioList.forEach(item=>{
              if(item.ratio) {
                item.ratio =  Number(item.ratio.split('%')[0])
              } else {
                item.ratio = 0
              }
            })
          }
          this.$nextTick(()=>{
            this.lastEcharts()
          })
          this.$forceUpdate()
          this.pageLoadFlag = false
        } else {
          this.$nextTick(()=>{
            this.lastEcharts()
          })
          this.pageLoadFlag = false
        }
      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    lastEcharts(){
      var myChart = this.$echarts.init(this.$refs.lastEcharts);
      let option = {
        series: [{
          name: "",
          type: "pie",
          radius: '68%',
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [{
            value: this.midHighData.midLowAmount,
            name: "中高端",
            selected: true
          },
            {
              value: this.midHighData.midHighAmount,
              name: "中低端"
            },

          ],
          itemStyle: {
            normal: {
              borderWidth: 3,
              borderColor: '#ffffff',
            },

          },
          color: [
            '#727EFF',
            '#FF8A8A',

          ],
        }],

      };
      myChart.setOption(option);
    },


    // 暂时不需要
    // 学习综合第一个
    drawChartfiveEcharts() {
      var myChart = this.$echarts.init(this.$refs.fiveEcharts);
      this.$forceUpdate()
      let chartdata = [0];
      let max = 100;
      let option={
        backgroundColor:"#F4F0FF",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:false,
            },
            showBackground: false,
            silent: false,
            itemStyle: {
              normal: {
                barBorderRadius: 20,
                color: '#18D1BC'
              },

            },
            barWidth: 20,
            data: [0]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 20,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 学习综合第er个
    drawChartsixEcharts() {
      var myChart = this.$echarts.init(this.$refs.sixEcharts);
      this.$forceUpdate()
      let chartdata = [30];
      let max = 100;
      let option={
        backgroundColor:"#F4F0FF",
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:false,
            },
            showBackground: false,
            silent: false,
            itemStyle: {
              normal: {
                barBorderRadius: 20,
                color: '#18D1BC'
              },

            },
            barWidth: 20,
            data: [70]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 20,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
  }
};
